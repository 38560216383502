.carousel-item div p {
  color: darkcyan;
  text-shadow: 0px 2px 2px rgba(255, 255, 255, 0.4);
}
.carousel-item div h3 {
  color: black;
  text-shadow: 0px 2px 2px rgba(255, 255, 255, 0.4);
  font-weight: bold;
}

.grow img {
  transition: 1s ease;
}

.grow img:hover {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  transition: 1s ease;
}
.carousel-indicators [data-bs-target] {
  background-color: #ff5722; /* Cambia el color de los puntos */
  border: 2px solid #ffffff; /* Agrega un borde blanco */
}

.carousel-indicators .active {
  background-color: #4caf50; /* Cambia el color del punto activo */
}
